import React from 'react'
import logo from '../img/logo.png';

export default function navbar() {
    return (
            <nav dir="rtl" className='navbar center'>
            <img src={logo} alt="site-logo" className="logo" />
            <ul className="nav-links center">
                <li><a href="#home">الرئيسية</a></li>
                <li><a href="#about">من نحن</a></li>
                <li><a href="#services">خدماتنا</a></li>
                <li><a href="#price">الباقات</a></li>
                <li><a href="#reviews">آراء العملاء</a></li>
                <li><a href="#courses">الكورسات</a></li>
            </ul>
            <a href="#contact">
            <button>تواصل معنا</button>
            </a>
            </nav>
    )
}