import React from 'react';
import benafits1 from "../img/benafits1.png";
import benafits2 from "../img/benafits2.png";
import benafits3 from "../img/benafits3.png";
import benafits4 from "../img/benafits4.png";
import benafits5 from "../img/benafits5.png";
import benafits6 from "../img/benafits6.png";
import benafitsnum1 from "../img/benafitsnum1.png";
import benafitsnum2 from "../img/benafitsnum2.png";
import benafitsnum3 from "../img/benafitsnum3.png";
import benafitsnum4 from "../img/benafitsnum4.png";
import benafitsnum5 from "../img/benafitsnum5.png";
import benafitsnum6 from "../img/benafitsnum6.png";

export default function Features() {
  return (
    <div className='features'>
        <h1>كيف يمكنك الاستفادة من خدماتنا</h1>
        <div className='features-imgs'>
          <ul className='features-ul center'>
            <li className='features-list'>
              <img className='features-num' src={benafitsnum1} alt=''/>
              <img className='features-img' src={benafits1} alt=''/>
              <h3 className='features-h'>الرغبة الشخصية</h3>
              <p className='features-p'>رغبتك الصادقة في حل 
              المشكلة هي الخطوة الأولى نحو التغيير</p>
            </li>
            <li className='features-list'>
              <img className='features-num' src={benafitsnum2} alt=''/>
              <img className='features-img' src={benafits2} alt=''/>
              <h3 className='features-h'>الجلسة الاستشارية</h3>
              <p className='features-p'>جلسة لمدة ساعة ونصف تستمع فيها 
              الكوتش لمشكلتك وتناقش كافة التفاصيل</p>
            </li>
            <li className='features-list'>
              <img className='features-num' src={benafitsnum3} alt=''/>
              <img className='features-img' src={benafits3} alt=''/>
              <h3 className='features-h'>تحليل جذر  المشكلة</h3>
              <p className='features-p'>تحليل دقيق وفهم شامل
              لجذور المشكلة للوصول إلى حلول فعّالة</p>
            </li>
            <li className='features-list'>
              <img className='features-num' src={benafitsnum4} alt=''/>
              <img className='features-img' src={benafits4} alt=''/>
              <h3 className='features-h'>خطة تربوية</h3>
              <p className='features-p'>حلول مخصصة تناسب احتياجاتك، 
              سواء كانت باكدج متكاملة أو حلول سريعة</p>
            </li>
            <li className='features-list'>
              <img className='features-num' src={benafitsnum5} alt=''/>
              <img className='features-img' src={benafits5} alt=''/>
              <h3 className='features-h'>التطبيق و المتابعة</h3>
              <p className='features-p'> تضمن تنفيذ الاستراتيجيات المقترحة مع 
              دعم مستمر لضمان تحقيق النتائج المطلوبة</p>
            </li>
            <li className='features-list'>
              <img className='features-num' src={benafitsnum6} alt=''/>
              <img className='features-img' src={benafits6} alt=''/>
              <h3 className='features-h'>الضمان الذهبي</h3>
              <p className='features-p'>في حال عدم رضاك خلال شهر،
              يحق لك استرداد كامل المبلغ المدفوع</p>
            </li>
          </ul>
        </div>
    </div>
  )
}
