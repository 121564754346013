import React from 'react';
import logo from "../img/logo.png";
import insta from "../img/instagram.png"
import whatsapp from "../img/whatsapp.png"
import tiktok from "../img/tik-tok.png"

export default function Footer() {
  return (
    <>
    <footer className='footer center' id='footer'>
        <div className="topside center">
        <img src={logo} alt="" />
        <div className="head-list">
            <h4>عن الكوتش أزهار</h4>
            <ul className='center'>
                <li><a href="#hero">الرئيسية</a></li>
                <li><a href="#about">من نحن</a></li>
                <li><a href="pricing">خدماتنا</a></li>
            </ul>
        </div>
        <div className="head-list">
            <h4>اهم الروابط</h4>
            <ul className='center'>
                <li><a href="#pricing">الباقات</a></li>
                <li><a href="#testi">آراء العملاء</a></li>
                <li><a href="#pricing">الكورسات</a></li>
            </ul>
        </div>
        <div className="head-list">
            <h4>تواصل معنا</h4>
            <ul className='center'>
                <li>
                    <a href="#">الممكلة العربية السعودية - المنطقة الشرقية</a>
                </li>
                <li>
                    <a href="mailto:answers@askcoachazhar.com">answers@askcoachazhar.com</a>
                </li>
            </ul>
        </div>
        <ul className='social'>
                <li>
                    <a href="https://www.instagram.com/coach_azhar_official/profilecard/?igsh=NXd2cGw3dmplc2U5">
                        <img src={insta} alt="" />
                    </a>
                </li>
                <li>
                    <a href="https://wa.me/966531378072">
                        <img src={whatsapp} alt="" />
                    </a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@coach_azhar_official?_t=8qtu5XX7moz&_r=1">
                        <img src={tiktok} alt="" />
                    </a>
                </li>
            </ul>
        </div>
        <hr />
        <p>جميع الحقوق محفوظة لدى <span>ازهار كوتش</span> -2024</p>
    </footer>
    </>
  )
}
