import React from 'react'
import aboutimg from '../img/about-img.png'
import icon1 from '../img/plan-icon.png';
import icon2 from '../img/box-icon.png';
import icon3 from '../img/screen-icon.png';
import icon4 from '../img/certificate-icon.png';
import afterHeroIcon from "../img/after-header-blue.png"
import starIcon from "../img/staricon.png"

export default function About() {
    return (
        <div className='about center flx-col' id='about'>
            <div className='about-sec'>
                <div className='right-half center flx-col'>
                    <div className="p-container">
                    <p>نبذة عني</p>
                    <img src={starIcon} alt="" className='before-p'/>
                    </div>
                    <div className="header-container">
                    <h1>تعرف علي الكوتش <span>أزهار</span></h1>
                    <img src={afterHeroIcon} alt="" className='after-header'/>
                    </div>
                    <p>أزهار هي مدربة أسرية متخصصه في حل المشاكل التربويه و السلوكيه
                    أم لطفلتين، حاصله على شهاده متقدمه في التدريب معتمده من المعهد التقني و المهني بالمملكة العربيه السعوديه، و خبرة تزيد عن ١٥ عام في مجال الصحه و الاستشارات الاسرية. ساعدت العديد من الأسر على تحسين الروابط الاسريه و تجاوز الصعوبات التربويه</p>
                </div>
                <div className='left-half'>
                    <img src={aboutimg} alt="about-sec" className="about-img" /> {/* src */}
                </div>
            </div>
            <div className='about-list'>
                <ul className='center'>
                    <li>
                        <img src={icon1} alt="about-icon" className="about-icon" /> {/* src */}
                        <h3>خطة تربوية </h3>
                        <p>لتقدم لك حلول فعاله للمشاكل التي تواجهك</p>
                    </li>
                    <li>
                        <img src={icon2} alt="about-icon" className="about-icon" /> {/* src */}
                        <h3>توفير الباقات التربوية</h3>
                        <p>لاحداث التغيير العميق بشكل فعال </p>
                    </li>
                    <li>
                        <img src={icon3} alt="about-icon" className="about-icon" /> {/* src */}
                        <h3>ورش تفاعلية </h3>
                        <p>تعزز المشاركة الفعّالة وتطوير المهارات</p>
                    </li>
                    <li>
                        <img src={icon4} alt="about-icon" className="about-icon" /> {/* src */}
                        <h3>ضمان ذهبي </h3>
                        <p>يتيح لك استراد اموالك خلال شهر من الخدمة </p>
                    </li>``
                </ul>
            </div>
        </div>
    )
}
