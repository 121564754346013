import React, { useState } from 'react';
import contactimg from "../img/contact.png";
import afterHeroIcon from "../img/after-header.png"
import starIcon from "../img/staricon.png";


const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    dateTime: '',
    country: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // استخدام FormData لإرسال البيانات عبر POST
    const formDetails = new FormData();
    formDetails.append('name', formData.name);
    formDetails.append('email', formData.email);
    formDetails.append('whatsapp', formData.whatsapp);
    formDetails.append('dateTime', formData.dateTime);
    formDetails.append('country', formData.country);
    formDetails.append('message', formData.message);

    // إرسال البيانات إلى send_email.php
    fetch('https://askcoachazhar.com//send_email.php', {
      method: 'POST',
      body: formDetails
    })
    .then(response => response.text())
    .then(data => {
      alert('تم إرسال الرسالة بنجاح!');
    })
    .catch(error => {
      console.error('Error:', error);
      alert('حدث خطأ أثناء إرسال الرسالة');
    });
  };

  return (
    <>
    <div className="contact-from center" id='contact'>
      <div className="right-half center">
        <div className="p-container">
        <p>تواصل معنا</p>
        <img src={starIcon} alt="" className='before-p'/>
        </div>
        <div className="header-container">
          <h1>هل لديك <span>استفسارات؟</span> <br></br>
          لا تتردد في التواصل معنا</h1>
          <img src={afterHeroIcon} alt="" className='after-header'/>
        </div>
        <img src={contactimg} alt="" />
      </div>
      <div className="left-half center">
        <h1>املئ بيانات النموذج </h1>
        <p>املئ بيانات النموذج و سنرد عليك باقرب و اسرع وقت  </p>
        <form onSubmit={handleSubmit} className='center'>
          <div className='input-cont center'>
            <label htmlFor="name">الاسم</label>
            <input className='name'
              type="text"
              name="name"
              placeholder="الاسم"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='input-group'>
            <div className='input-cont center'>
            <label htmlFor="email">البريد الالكتروني</label>
            <input
              type="email"
              name="email"
              placeholder="ادخل عنوان بريدك الالكتروني "
              value={formData.email}
              onChange={handleChange}
              required
            />
            </div>
          <div className="input-cont center">
            <label htmlFor="whatsapp">ادخل رقم الوتساب</label>
            <input
              type="text"
              name="whatsapp"
              placeholder="رقم الواتساب"
              value={formData.whatsapp}
              onChange={handleChange}
              required
            />
          </div>
          </div>
          <div className='input-group'>
            <div className="input-cont center">
              <label htmlFor="dateTime">الوقت والتاريخ</label>
              <input
                type="date"
                name="dateTime"
                placeholder="متى تريد الاستشارة"
                value={formData.dateTime}
                onChange={handleChange}
                required
              />
            </div>
          <div className='input-cont center'>
            <label htmlFor="country">الدولة</label>
            <input
              type="text"
              name="country"
              placeholder="ادخل من اي بلد تتواصل معنا"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
          </div>
          <div className='input-cont center'>
            <label htmlFor="message">الرسالة</label>
            <textarea
              name="message"
              placeholder="نبذة عن موضوع الاستشارة"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">ارسال</button>
        </form>
      </div>
    </div>
    </>
  );
};

export default ContactForm;


