import logo from './logo.svg';
import './App.css';
import Navbar from './sections/Navbar';
import Herosec from './sections/Herosec';
import About from './sections/About';
import Pricing from './sections/Pricing';
import Features from './sections/Features';
import TestimonialsSlider from './sections/TestimonialsSlider';
import ContactForm from './sections/ContactForm';
import Footer from './sections/Footer';

function App() {
  return (
    <div className="App" dir="rtl">
      <Navbar />
      <Herosec />
      <About />
      <Pricing />
      <Features />
      <TestimonialsSlider />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
