import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './TestimonialsSlider.css'; // ملف CSS مخصص للتنسيق
// import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import arrowIcon from "../img/arrowicon.png"
import quoteIcon1 from "../img/quote1.png";
import quoteIcon2 from "../img/quote1-2.png";
import quoteIconBlue1 from "../img/quote2.png";
import quoteIconBlue2 from "../img/quote2.png";
import starIcon from "../img/staricon.png"

// حلول 
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import afterHeroIcon from "../img/after-header-blue.png"


const testimonials = [
  { name: 'ام جواد', rating: 5, text: 'شكرا كتير كوتش ازهار انك سمعتيني و فرغت عن مشاعري و شكرا لنصايحك لأعطيتيها كتير فرحت انو تواصلت معك' },
  { name: 'ام فاطمة', rating: 5, text: 'صباحك خير وسعادة ومبارك عليش الشهر يارب كانت نص ساعه ثمينه وكلامش يدخل القلب على طول رجعت البيت وحاولت اطبق كلامش في جودة الوقت الحمد لله مرتاحة جداً معاهم الله بسعدش يارب' },
  { name: 'ام محمد', rating: 5, text: 'كوتش ازهار .. سعيده اليوم بلقائك جدا كان حوار جميل الصراحه انتبهت على أمور رائعه خلتني اركز عليها بأحد جوانب حياتي .. أعتطيتني الامل وجعلتي..' }
];

const TestimonialsSlider = () => {
  return (
    <div className="testi-sec" id='testi'>
      <div className="testi-txt center">
        <div className="right-half">
          <div className="p-container">
          <p>اراء العملاء</p>
          <img src={starIcon} alt="" className='before-p'/>
          </div>
          <div className="header-container">
          <h1>قصص <span>النجاح</span> من عملائنا</h1>
          <img src={afterHeroIcon} alt="" className='after-header'/>
          </div>
          <p>اكتشف تجارب عملائنا الناجحة في تحسين سلوكيات أطفالهم وبناء علاقات أسرية أقوى من خلال جلساتنا التربوية المتخصصة. هنا ستجد قصصاً واقعية تلهمك وتثبت فعالية خدماتنا.</p>
        </div>
        <div className="left-half">
          <button>اقرأ المزيد</button>
        </div>
      </div>
      <Swiper
      modules={[Navigation, Pagination]}
      initialSlide={1}
      spaceBetween={50}
      slidesPerView={1.7} // عرض 3 شرائح في وقت واحد
      centeredSlides={true} // جعل الشريحة الوسطى في المنتصف
      navigation
      pagination={{ clickable: true }}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <div className="testimonial-card ">
            <div className="testi-icons center ">
              <img src={arrowIcon} alt="" />
              <img src={quoteIcon1} alt="" />
            </div>
            <p className="stars">{"★".repeat(testimonial.rating)}{"☆".repeat(5 - testimonial.rating)}</p>
            <p>{testimonial.text}</p>
            <div className="testi-name center ">
              <img src={quoteIcon2} alt="" />
              <h3>{testimonial.name}</h3>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
  );
};


export default TestimonialsSlider;
