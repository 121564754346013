import React from 'react';
import heropic1 from '../img/heropic-1.png';
import heropic2 from '../img/heropic-2.png';
import heropic3 from '../img/heropic-3.png';
import afterHeroIcon from "../img/after-hero.png"
// sec 
export default function Herosec() {
  return (
    <div className='herosec' id='pricing'>
        <div className='txt-content center'>
        <div className='header-container'>
        <h1>تغيير سلوك <span>طفلك</span> يبدأ معنا <br></br>
        استشارات لبناء علاقة أسرية أقوى</h1>
        <img src={afterHeroIcon} alt="" className='after-header'/>
        </div>
        <p>من هنا تبدأ رحلتك نحو فهم أعمق لاحتياجات طفلك وتطوير استراتيجيات فعالة لتغيير تلك السلوكيات للأفضل. مع الاستشارات المتخصصة التي نقدمها، ستتمكنين من بناء علاقة أسرية متينة تقوم على الاحترام المتبادل والتواصل الفعّال، مما يخلق بيئة صحية لنمو طفلك بشكل سليم واستقرار أسرتك على المدى الطويل</p>
        <a href="#one-session-price">
          <button>احجز جلستك الاستشارية الآن</button>
        </a>
        </div>
        <div className='img-hero-sec'>
            <ul className='center'>
                <li><img src={heropic1} alt="hero-img" className="hero-img1" /></li>
                <li><img src={heropic3} alt="hero-img" className="hero-img3" /></li>
            </ul>
            <img src={heropic2} alt="hero-img" className="hero-img2" />
        </div>
    </div>
  )
}
