import React from 'react';
import headerIcon from "../img/priceing_icon.png"
import durationIcon from "../img/time-duration-icon.png"
import cardIcon1 from "../img/Icon-cards1.png";
import cardIcon2 from "../img/cal-icon2.png";
import cardIcon3 from "../img/icon3.png";
import afterHeroIcon from "../img/after-header.png"

export default function Pricing() {
  return (
    <>
    <div className='pricing-header center' id='pricing'>
        <h1>ساعدي <span>طفلك</span> لتحقيق</h1>
        <img src={headerIcon} alt=''/>
        <h1 className='center arrow'>التوازن <span>والاستقرار</span></h1>
    </div>
    <div className='pricing-dis center'>
        <div className="big-cont">
            <div className="header-container">
                <h1>خدماتنا التربوية  لبناء <br></br>
                حياة <span>أسرية سعيدة</span> ومستقرة</h1>
                <img src={afterHeroIcon} alt="" className='after-header' />
            </div>
        </div>
        <p>استفد من جلسات استشارية فردية مصممة خصيصًا لاحتياجاتك، أو اختر من بين باقاتنا الشهرية الشاملة لتحقيق تغييرات مستدامة في علاقتك بأطفالك وبناء حياة <span>أسرية سعيدة</span> ومستقرة</p>
    </div>
    <div className='pricing-list center'>
        <div className='single-list center' id='one-session-price'>
            <p className='sub-header'>الجلسات الاستشارية</p>
            <img src={cardIcon1} alt="" className='icon-img' />
            <h2 >جلسة الاستشارة الواحدة</h2>
            <img src='' alt='' className='icon'/>
            <p className='listing-p'>جلسة استشارية مطولة متخصصة لمناقشة مشكلة  تربوية أو سلوكية لطفلك او مشكله أسرية تحدث معك وتقديم خطة عمل عملية لحل المشكلة .</p>
            <div className='list-duration center'>
                <img src={durationIcon} alt='' className='duration-icon'/>
                <h3>مدة الجلسة :</h3>
                <p> 90 دقيقة</p>
            </div>
            <hr></hr>
            <div className='list-features' >
                <h4>المميزات</h4>
                <p><span>تحليل فردي:</span>تركيز كامل على مشكلة واحدة محددة.</p>
                <p><span>حلول فورية:</span>توجيهات عملية يمكن تطبيقها فورًا.</p>
                <p><span>دعم شخصي:</span> الاستشارة مع الكوتش أزهار بشكل مباشر.</p>
            </div>
            <p className='list-price center'><h1>280ر.س</h1>/جلسة</p>
            <a href="https://fato.me/s/azharstore/i947881">
            <button >
            احجز جلستك
            </button>
            </a>
        </div>
        <div className='single-list center'>
            <p className='sub-header'>الباقات الشهرية</p>
            <img src={cardIcon2} alt="" className='icon-img'/>
            <img src='' alt='' className='icon'/>
            <h2>الخطة التربوية الشهرية</h2>
            <p className='listing-p'>برنامج تربوي شامل بخطة معدة خصصيا لك يمتد لمدة شهر لتحسين السلوك المستهدف لك او لطفك على المدى الطويل.</p>
            <div className='list-duration center'>
                <img src={durationIcon} alt='' className='duration-icon'/>
                <h3>مدة الجلسة :</h3>
                <p> 3 جلسات بالشهر</p>
            </div>
            <hr></hr>
            <div className='list-features'>
                <h4>المميزات</h4>
                <p><span>متابعة مستمرة:</span>جلسات كل اسبوعين لتقييم التطورات.</p>
                <p><span>خطة مخصصة:</span>برنامج تربوي مصمم خصيصًا لاحتياجات طفلك.</p>
                <p><span>توجيه مستمر:</span>دعم ومتابعة خلال الشهر لضمان تحقيق النتائج المطلوبة.</p>
            </div>
            <a href="https://wa.me/966531378072">
            <button className='without-price'>تواصل معنا</button>
            </a>
            
        </div>
        <div className='single-list center'>
            <p className='sub-header'>الباقات الشهرية</p>
            <img src={cardIcon3} alt="" className='icon-img' />
            <img src='' alt='' className='icon'/>
            <h2>باقة الثلاثة أشهر</h2>
            <p className='listing-p'>برنامج طويل الأمد لبناء شخصية مميزة لطفلك   أو تنمية مهارات المربي التربوية تحت اشراف و متابعة مستمرة </p>
            <div className='list-duration center'>
                <img src={durationIcon} alt='' className='duration-icon'/>  
                <h3>مدة الجلسة :</h3>
                <p> ٧ جلسات في ثلاث اشهر</p>
            </div>
            <hr></hr>
            <div className='list-features'>
                <h4>المميزات</h4>
                <p><span>خطة متكاملة:</span>برنامج تربوي شامل يمتد على مدى ثلاث أشهر لضمان استقرار السلوك.</p>
                <p><span>جلسات متعددة:</span>جلسات استشارية  مع دعم إضافي عند الحاجة.</p>
                <p><span>نتائج مضمونة:</span>اتوفير الوقت الكافي لتطبيق التغييرات ومتابعة تقدم الطفل.</p>
            </div>
            <a href="#contact">
                <button className='without-price'>تواصل معنا لمعرفة تفاصيل</button>
            </a>
        </div>
    </div>
    </>
  )
}
